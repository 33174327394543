import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $cards = $('.show-card');
    const $sorted = $cards.clone();
    $sorted.sort(() => (Math.round(Math.random()) - 0.5));
    for (let i = 0; i < $cards.length; i += 1) $cards.eq(i).replaceWith($sorted[i]);
    $sorted.each((_, el) => {
        const $this = $(el);
        const label = $this.find('.heading').text();
        const $button = $this.find('a');
        $this.on('click', (e) => {
            if (!$(e.target).is($button)) {
                $button[0].click();
            }
        });
        $button.data('gtm', { label, type: 'selectShow' });
    });
}, 5);
